<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row.mb-3
    .col-lg-12.title-box
      TimePeriodTab(:tab="statusTab" @tab-change="changeStatusBar")
  .row
    .col-lg-3
      .card.mb-2
        .card-body
          h4.header-title WEB 30 分內訪客數量 / 第一方
          h3.font-weight-light.mt-3.mb-1 
            span(v-if="analytics.web.visitors") {{ $root.common.formatNumber(analytics.web.visitors, 0)  }}
            span(v-else) --,---
          p.text-muted.text-right.mb-0 即時 / 使用者
    .col-lg-3
      .card.mb-2
        .card-body
          h4.header-title WEB 30 分內訪客數量 / GA4
          h3.font-weight-light.mt-3.mb-1 
            span(v-if="analytics.web.ga_visitors") {{ $root.common.formatNumber(analytics.web.ga_visitors, 0)  }}
            span(v-else) --,---
          p.text-muted.text-right.mb-0 即時 / 使用者
    .col-lg-3
      .card.mb-2
        .card-body
          h4.header-title APP 30 分內訪客數量 / 第一方
          h3.font-weight-light.mt-3.mb-1 
            span(v-if="analytics.app.visitors") {{ $root.common.formatNumber(analytics.app.visitors, 0)  }}
            span(v-else) --,---
          p.text-muted.text-right.mb-0 即時 / 使用者
    .col-lg-3
      .card.mb-2
        .card-body
          h4.header-title APP 30 分內訪客數量 / GA4
          h3.font-weight-light.mt-3.mb-1 
            span(v-if="analytics.app.ga_visitors") {{ $root.common.formatNumber(analytics.app.ga_visitors, 0)  }}
            span(v-else) --,---
          p.text-muted.text-right.mb-0 即時 / 使用者
    //- .col-lg-3
      .card.mb-2
        .card-body
          h4.header-title 全站當日流量 / GA4
          h3.font-weight-light.mt-3.mb-1 123,123 / 120,000 
          p.text-muted.text-right.mb-0 (工作階段) 
    //- .col-lg-3
      .card.mb-2
        .card-body
          h4.header-title 流量最高中心-要聞中心
          h3.font-weight-light.mt-3.mb-1 5,123,124
          p.text-muted.text-right.mb-0 (page view) 
  .row 
    .col-lg-12
      .card.mb-2
        .card-body
          h4.header-title Google Trends 關鍵字
          .d-flex.justify-content-between
            p.font-weight-light.mt-3.mb-1.mx-2(
              :key="word.id"
              v-for="( word, index ) in analytics.keywords"
            )
              span No.{{ index + 1 }} 
              span.h2.mx-1 {{ word.title }} 
              | ({{ $root.common.formatNumber(word.traffic, 0) }}+)
            p.font-weight-light.mt-3.mb-1.mx-2
              a(target="new" href="https://trends.google.com/trends/trendingsearches/daily?geo=TW&hl=zh-TW")
                span google trend>> 
  .row
    .col-lg-12
      .card.mb-2
        .card-body.d-flex.align-items-center.justify-content-between
          h4.header-title 
            span 地區來源
            br
            small (不重複用戶)
          div.flex-grow-1.d-flex
            apexchart.mx-auto(width='850' height='300' :series='barChartOption.series' :options='barChartOption')

    .col-lg-12
      .card.mb-2
        .card-body.d-flex.align-items-center.justify-content-between
          h4.header-title 
            span 各管道流量
            br
            small (不重複用戶)
          div.flex-grow-1.d-flex
            apexchart.mx-auto(width='850' height='300' :series='referrersCharOption.series' :options='referrersCharOption')
            //- apexchart.mx-auto(v-else, width='850' height='300' :series='lineChartOption.series' :options='lineChartOption')




  .row

    .col-lg-6
      .card.mb-2
        .card-body
          h4.header-title 30分鐘熱門分類頁排行(pv)
          .info(v-if="!hotTypePage.length")
            .text-center 目前無資料
          .info(v-else)
            ol.pl-3.m-0.my-2
              li(v-for="item in hotTypePage" :key="item.id") 
                .d-flex.justify-content-between
                  span.pb-1.mr-2.flex-grow-1.text-truncate {{ item.title }}
                  span(style="min-width: 56px;").text-right.pb-1.mx-2 {{ $root.common.formatNumber(item.views, 0) }}
                  //- span(style="min-width: 45px;").text-right.pb-1.mx-2 {{ item.percentage }}%

            //- router-link(to='/news-analysis')
              b-button.btn-block(variant="secondary") 看更多排行

    .col-lg-6
      .card.mb-2
        .card-body
          h4.header-title 10分鐘熱門分類頁排行(pv)
          .info(v-if="!hot10MinTypePage.length")
            .text-center 目前無資料
          .info(v-else)
            ol.pl-3.m-0.my-2
              li(v-for="item in hot10MinTypePage" :key="item.id") 
                .d-flex.justify-content-between
                  span.pb-1.mr-2.flex-grow-1.text-truncate {{ item.title }}
                  span(style="min-width: 56px;").text-right.pb-1.mx-2 {{ $root.common.formatNumber(item.views, 0) }}
                  //- span(style="min-width: 45px;").text-right.pb-1.mx-2 {{ item.percentage }}%

            //- router-link(to='/news-analysis')
              b-button.btn-block(variant="secondary") 看更多排行


    .col-lg-4
      .card.mb-2
        .card-body
          h4.header-title 10分鐘熱門新聞排行(pv)
          .info(v-if="!hot10MinArticles.length")
            .text-center 目前無資料
          .info(v-else)
            ul.pl-3.m-0.my-2.list-title-style
              li
                .d-flex.justify-content-between
                  span.pb-1.mr-2.flex-grow-1.text-truncate1 稿件標題
                  span(style="min-width: 56px;").text-right.pb-1.mx-2 觀看
            ol.pl-3.m-0.my-2
              li(v-for="item in hot10MinArticles" :key="item.id") 
                .d-flex.justify-content-between
                  span.pb-1.mr-2.flex-grow-1.text-truncate {{ item.title }}
                  span(style="min-width: 56px;").text-right.pb-1.mx-2 {{ $root.common.formatNumber(item.views, 0) }}
            //- router-link(to='/news-analysis')
              b-button.btn-block(variant="secondary" to="/news-analysis") 看更多排行

    .col-lg-4
      .card.mb-2
        .card-body
          h4.header-title 30分鐘熱門新聞排行(pv)
          .info(v-if="!hotArticles.length")
            .text-center 目前無資料
          .info(v-else)
            ul.pl-3.m-0.my-2.list-title-style
              li
                .d-flex.justify-content-between
                  span.pb-1.mr-2.flex-grow-1.text-truncate1 稿件標題
                  span(style="min-width: 56px;").text-right.pb-1.mx-2 觀看
            ol.pl-3.m-0.my-2
              li(v-for="item in hotArticles" :key="item.id") 
                .d-flex.justify-content-between
                  span.pb-1.mr-2.flex-grow-1.text-truncate {{ item.title }}
                  span(style="min-width: 56px;").text-right.pb-1.mx-2 {{ $root.common.formatNumber(item.views, 0) }}
            //- router-link(to='/news-analysis')
              b-button.btn-block(variant="secondary" to="/news-analysis") 看更多排行

    //- .col-lg-6
      .card.mb-2
        .card-body
          h4.header-title 即時中心
          .info(v-if="!data.lenght")
            .text-center 目前無資料
          .info(v-else)
            ol.pl-3.m-0.my-2
              li(v-for="item in data" :key="item.id") 
                .d-flex.justify-content-between
                  span.pb-1.mr-2.flex-grow-1.text-truncate {{ item.content }}
                  span(style="min-width: 56px;").text-right.pb-1.mx-2 {{ $root.common.formatNumber(item.count, 0) }}
            b-button.btn-block(variant="secondary" to="/center-analysis") 看更多排行
    //- .col-lg-6
      .card.mb-2
        .card-body
          h4.header-title 影音節目播放排行(pv)
          .info(v-if="!data.lenght")
            .text-center 目前無資料
          .info(v-else)
            ol.pl-3.m-0.my-2
              li(v-for="item in data" :key="item.id") 
                .d-flex.justify-content-between
                  span.pb-1.mr-2.flex-grow-1.text-truncate {{ item.content }}
                  span(style="min-width: 56px;").text-right.pb-1.mx-2 {{ $root.common.formatNumber(item.count, 0) }}
            b-button.btn-block(variant="secondary" to="/media-program-analysis") 看更多排行

    //- .col-lg-4
      .card.mb-2
        .card-body
          h4.header-title 中心流量排行(pv)
          .info(v-if="!data.length")
            .text-center 目前無資料
          .info(v-else)
            ol.pl-3.m-0.my-2
              li(v-for="item in data" :key="item.id") 
                .d-flex.justify-content-between
                  span.pb-1.mr-2.flex-grow-1.text-truncate {{ item.content }}
                  span(style="min-width: 56px;").text-right.pb-1.mx-2 {{ $root.common.formatNumber(item.count, 0) }}
                  span(style="min-width: 45px;").text-right.pb-1.mx-2 {{ item.percentage }}%
            //- b-button.btn-block.btn.btn-secondary 看更多排行

    .col-lg-4
      .card.mb-2
        .card-body
          h4.header-title 今日編輯人員流量排行
          .info(v-if="!HotAuthors.length")
            .text-center 目前無資料
          .info(v-else)
            ul.pl-3.m-0.my-2.list-title-style
              li
                .d-flex.justify-content-between
                  span.pb-1.mr-2.flex-grow-1.text-truncate1 作者名稱
                  span(style="min-width: 60px;").text-right.pb-1.mx-2 總觀看
                  span(style="min-width: 50px;").text-right.pb-1.mx-2 總稿量
            ol.pl-3.m-0.my-2
              li(v-for="item in HotAuthors" :key="item.id") 
                .d-flex.justify-content-between
                  span.pb-1.mr-2.flex-grow-1.text-truncate {{ item.editer_niname }}
                  span(style="min-width: 60px;").text-right.pb-1.mx-2 {{ $root.common.formatNumber(item.views, 0) }} 
                  span(style="min-width: 50px;").text-right.pb-1.mx-2 {{ $root.common.formatNumber(item.Articles, 0) }} 
            //- b-button.btn-block.btn.btn-secondary 看更多排行


    .col-lg-6
      .card.mb-2
        .card-body
          h4.header-title 30分鐘外稿流量排行(pv)
          .info(v-if="!hotRssArticle.length")
            .text-center 目前無資料
          .info(v-else)
            ol.pl-3.m-0.my-2
              li(v-for="item in hotRssArticle" :key="item.id") 
                .d-flex.justify-content-between
                  span.pb-1.mr-2.flex-grow-1.text-truncate {{ item.title }}
                  span(style="min-width: 56px;").text-right.pb-1.mx-2 {{ $root.common.formatNumber(item.views, 0) }}
            //- b-button.btn-block(variant="secondary" to="/external-content-analysis") 看更多排行

    .col-lg-6
      .card.mb-2
        .card-body
          h4.header-title 30分鐘稿件分類排行(pv)
          .info(v-if="!data.length")
            .text-center 目前無資料
          .info(v-else)
            ol.pl-3.m-0.my-2
              li(v-for="item in data" :key="item.id") 
                .d-flex.justify-content-between
                  span.pb-1.mr-2.flex-grow-1.text-truncate {{ item.content }}
                  span(style="min-width: 56px;").text-right.pb-1.mx-2 {{ $root.common.formatNumber(item.count, 0) }}
                  span(style="min-width: 45px;").text-right.pb-1.mx-2 {{ item.percentage }}%
            //- b-button.btn-block.btn.btn-secondary 看更多排行
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import TimePeriodTab from "./components/TimePeriodTab";

/**
 * Starter component
 */
export default {
  name: 'Dashboard',
  data () {
    return {
      title: "Dashboard / 數據分析",
      items: [{
        text: "數據分析",
        // href: "/dashboard",
      },
      {
        text: "Dashboard",
        active: true,
      }
      ],
      data: [],
      hot10MinArticles: [],
      hot10MinTypePage: [],
      hotArticles: [],
      hotTypePage: [],
      hotRssArticle: [],
      HotAuthors: [],
      statusTab: 0,
      barChartOption: {
        chart: {
          height: 350,
          type: "bar"
        },
        dataLabels: {
          enabled: false
        },
        series: [],
        colors: ["#FF1654", "#247BA0", "#e87613", "#2f8f27", "#5f1eba"],
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        xaxis: {
          categories: []
        },
        legend: {
          position: "right",
          offsetY: 40
        }
      },
      referrersCharOption: {
        chart: {
          height: 350,
          type: "bar"
        },
        dataLabels: {
          enabled: false
        },
        series: [{
          name: 'm/facebook',
          data: [125]
        },
        {
          name: 'direct',
          data: [10]
        },
        {
          name: 'referal',
          data: [20]
        },
        {
          name: 'LINE',
          data: [30]
        },
        {
          name: 'yahoo',
          data: [43]
        }],
        colors: ["#FF1654", "#247BA0", "#e87613", "#2f8f27", "#5f1eba"],
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        xaxis: {
          categories: []
        },
        legend: {
          position: "right",
          offsetY: 40,
          onItemHover: {
            highlightDataSeries: true
          },
        },
        stroke: {
          curve: "smooth"
        }
      },
      interval: false,
      analytics: {
        web: {
          visits: 0,
          actions: 0,
          visitors: 0,
          ga_visits: 0,
          ga_actions: 0,
          ga_visitors: 0,
        },
        app: {
          visits: 0,
          actions: 0,
          visitors: 0,
          ga_visits: 0,
          ga_actions: 0,
          ga_visitors: 0,
        },
        keywords: []
      }
    };
  },
  created () {
    this.reloadData()
    this.interval = setInterval(this.getCurrentActiveUsers, 10000)
  },
  methods: {
    // 變更狀態夾
    changeStatusBar (_status) {
      this.statusTab = _status
      this.currentPage = 1
      this.reloadData()
    },
    reloadData () {
      this.getGoogleTrendsKeyWrods()
      this.getUserRegionList()
      this.getUserReferrersList()
      this.getCurrentActiveUsers()
    },
    getCurrentActiveUsers () {
      let vm = this
      this.$root.apis.getActiveUsers(1, 'web', function (_response) {
        let data = _response.body.data
        vm.analytics.web.visits = data.visits
        vm.analytics.web.actions = data.actions
        vm.analytics.web.visitors = data.visitors
      })
      this.$root.apis.getActiveUsers(1, 'app', function (_response) {
        let data = _response.body.data
        vm.analytics.app.visits = data.visits
        vm.analytics.app.actions = data.actions
        vm.analytics.app.visitors = data.visitors
      })
      this.$root.apis.getActiveUsers(2, 'web', function (_response) {
        let data = _response.body.data
        vm.analytics.web.ga_visits = data.visits
        vm.analytics.web.ga_actions = data.actions
        vm.analytics.web.ga_visitors = data.visitors
      })
      this.$root.apis.getActiveUsers(2, 'app', function (_response) {
        let data = _response.body.data
        vm.analytics.app.ga_visits = data.visits
        vm.analytics.app.ga_actions = data.actions
        vm.analytics.app.ga_visitors = data.visitors
      })
      this.$root.apis.getHotArticle(10, this.processHot10MinArticle)
      this.$root.apis.getHotTypePage(10, this.processHot10MinTypePage)
      this.$root.apis.getHotArticle(30, this.processHot30MinArticle)
      this.$root.apis.getHotTypePage(30, this.processHot30MinTypePage)
      this.$root.apis.getHotRssArticle(30, this.processHot30RssArticle)
      this.$root.apis.getHotAuthors(this.processHotAuthors)
    },
    async getGoogleTrendsKeyWrods () {
      let vm = this
      let keywords = []
      await this.$root.apis.getGoogleTrendsList(async function (_response) {
        let words = await _response.body.data.words
        await words.forEach(function (_item, _index) {
          if (_index <= 2) {
            keywords.push(_item)
          }
        })
      })

      vm.analytics.keywords = await []
      vm.analytics.keywords = await keywords
    },
    async getUserRegionList () {
      let vm = this
      await this.$root.apis.getUserRegionList('web', async function (_response) {
        let data = _response.body.data
        await console.log('--> 地區資料列數:', data.length)

        // 難過的資料組合
        let series = []
        await data.forEach(async function (_item, _itemIndex) {
          let datarow = await vm.makeArray(_item, _itemIndex, data.length)
          await series.push(datarow)
          await vm.barChartOption.xaxis.categories.push(_item.label)
        })
        await console.log('--> 資料組合結果', series)
        vm.barChartOption.series = await series
      })
    },
    async getUserReferrersList () {
      let vm = this
      await this.$root.apis.getUserReferrersList('web', (this.statusTab + 1), async function (_response) {
        let data = _response.body.data
        await console.log('--> 引薦資料列數:', data.length)

        let series = []
        switch (vm.statusTab) {
          // 今日資料
          case 0:
            await data.forEach(async function (_item, _itemIndex) {
              let datarow = await vm.makeArray(_item, _itemIndex, data.length)
              await series.push(datarow)
              await vm.referrersCharOption.xaxis.categories.push(_item.label)
            })
            await console.log('--> 資料組合結果', series)
            vm.referrersCharOption.series = await series
            break;

          // 7 日內資料
          case 2:
            await data.forEach(async function (_item, _itemIndex) {
              let datarow = await vm.makeArray(_item, _itemIndex, data.length)
              await series.push(datarow)
            })
            await console.log('--> 資料組合結果', series)
            vm.lineChartOption.series = await series
            break;
        }
      })
    },
    async makeArray (_item, _itemIndex, _lenght) {
      let array = []
      for (let i = 0; i < _lenght; i++) {
        // 置入地區數值
        if (_itemIndex === i) {
          array.push(_item.nb_uniq_visitors)
        }

        // 置入資料為 0
        else {
          array.push(0)
        }
      }

      console.log('--> 建立項目[' + _itemIndex + '][' + _item.nb_uniq_visitors + ']: ', _item.label, array);
      return { name: _item.label, data: array }
    },
    processHot10MinArticle (_response) {
      this.hot10MinArticles = _response.body.data
    },
    processHot10MinTypePage (_response) {
      this.hot10MinTypePage = _response.body.data
    },
    processHot30MinArticle (_response) {
      this.hotArticles = _response.body.data
    },
    processHot30MinTypePage (_response) {
      this.hotTypePage = _response.body.data
    },
    processHot30RssArticle (_response) {
      this.hotRssArticle = _response.body.data
    },
    processHotAuthors (_response) {
      this.HotAuthors = _response.body.data
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    TimePeriodTab
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
};
</script>

<style lang="sass" scoped>
  .card-header
    background: #fff
  .label-select
    border: none
    padding: 0
    height: auto
    line-height: 1
    font-weight: 600
  .header-row
    display: flex
    align-items: center
    .header-title
      margin: 0
      flex: 1
  .tab-group
    background: rgba(229,231,235,1)
    padding: 0.5rem 1rem
    button.off
      color: rgba(156,163,175,1)    
      background: rgba(0,0,0,0)
      border-color: rgba(0,0,0,0)
  ::v-deep .width-auto
    min-width: 7rem
    white-space: nowrap
    vertical-align: middle
    &.center
      text-align: center
  .list-title-style
    list-style: none
    margin-bottom: 0px !important
    border-bottom: 2px solid #aaa  
</style>